// TODO: add stricter type annotations to each of these functions
/**
 *
 * @param connections
 */
function constructLinesById(lines: any) {
  // Index lines by id to speed up lookups
  var lines_by_id: any = {}

  lines.forEach((item: any) => {
    lines_by_id[item.line] = item.colour
  })

  return lines_by_id
}

export default constructLinesById
