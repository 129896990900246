import * as React from 'react'
import _ from 'lodash'

const useResizeObserver = (ref: any): any => {
  const [dimensions, setDimensions] = React.useState(null)

  React.useEffect(() => {
    const observeTarget = ref.current
    const resizeObserver = new ResizeObserver((entries) => {
      // Set resized dimensions here
      entries.forEach((entry) => {
        // @ts-ignore
        _.throttle(() => setDimensions(entry.contentRect), 500)()
      })
    })
    resizeObserver.observe(observeTarget)

    return () => {
      resizeObserver.unobserve(observeTarget)
    }
  }, [ref])
  return dimensions
}

export default useResizeObserver
