// TODO: add stricter type annotations to each of these functions
/**
 *
 * @param connections
 */
function indexNodesAndConnections(connections: any, stations: any) {
  var nodesAreLinked: any = {}
  var nodesById: any = {}

  connections.forEach((connection: any) => {
    // @ts-ignore
    nodesAreLinked[`${connection.station1}-${connection.station2}`] = true
  })

  stations.forEach((station: any) => {
    // @ts-ignore
    nodesAreLinked[`${station.id}-${station.id}`] = true
    // @ts-ignore
    nodesById[station.id] = station
  })
  return { nodesAreLinked, nodesById }
}

export default indexNodesAndConnections
