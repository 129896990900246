import * as React from 'react'
import LondonForce from '../components/Visualization/LondonForce'

const LondonUndergroundForce = () => {
  return (
    <div className="m-0 p-0 min-h-screen">
      <div className="mx-auto py-8" style={{ width: '100vw', height: '100vh' }}>
        <LondonForce />
      </div>
    </div>
  )
}

export default LondonUndergroundForce
