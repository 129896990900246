import * as React from 'react'
import * as d3 from 'd3'

import constructNodesWithLinks from './helpers/constructNodesWithLinks'
import constructLinesById from './helpers/constructLinesById'
import constructStationNodes from './helpers/constructStationNodes'
import indexNodesAndConnections from './helpers/indexNodesAndConnections'
import detectClusters from './helpers/detectClusters'

import useResizeObserver from '../../utils/hooks/useResizeObserver'
import data from './london.json'

const LondonForce = () => {
  const svgRef = React.useRef() as React.MutableRefObject<SVGSVGElement>
  const wrapperRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
  const dimensions: any = useResizeObserver(wrapperRef)

  // Data preprocessing
  const { connections, lines, stations } = data

  React.useEffect(() => {
    // return immediately without visualization if dimensions are not defined
    if (!dimensions) return

    d3.select(svgRef.current).selectAll('g').remove()

    const svg = d3
      .select(svgRef.current)
      .append('g')
      .attr('id', 'london-underground-connections')

    //////////////////////////////////////////////////
    /////////////// MAIN VISUALIZATION ///////////////
    //////////////////////////////////////////////////

    ////////// DATA PREPARATION //////////
    const nodesWithLinks = constructNodesWithLinks(connections)
    const stationNodes = constructStationNodes(stations, nodesWithLinks)
    const linesById = constructLinesById(lines)

    // Index nodes and connections by id to speed up lookups
    const { nodesAreLinked, nodesById } = indexNodesAndConnections(
      connections,
      stations,
    )

    // Detect clusters
    const clusters_by_id = detectClusters(connections)

    // ========================================
    // SVG manipulations
    // ========================================

    // Define colors
    var fade_out_color = '#414c50'

    // Define scale functions
    var freqs: any = d3.extent(stations, (station) => station.total_lines)
    var node_radius: any = d3.scaleLinear().domain(freqs).range([0, 1])

    svg.attr(
      'transform',
      `translate(${dimensions.width / 2}, ${dimensions.height / 2})`,
    )

    // Define the links
    var links_svg_stuff = svg
      .selectAll('.link')
      .data(data.connections)
      .enter()
      .append('path')
      .attr('class', 'link')
      .style('fill', 'none')
      .style('stroke', (d) => `#${linesById[d.line]}`)
      .style('stroke-width', () => 3)

    // Define the nodes
    var nodes_svg_stuff = svg
      .selectAll('.node')
      .append('g')
      .attr('id', 'nodes-svg-stuff')
      .data(data.stations)
      .enter()
      .append('circle')
      .attr('class', 'node')
      .attr('r', 9)
      .style('fill', 'white')
      .style('stroke', fade_out_color)
      .style('stroke-width', 6)

    // Define the node labels (shown on mouseover/click)
    var texts_svg = svg
      .selectAll('text')
      .data(stations)
      .enter()
      .append('text')
      .attr('pointer-events', 'none')
      .attr('alignment-baseline', 'middle')
      .style('font-family', 'Helvetica, Arial')
      .style('font-size', '22px')
      .style('fill', 'black')
      .text((d) => d.name)

    // Start the force simulation
    const simulation: any = d3
      // @ts-ignore
      .forceSimulation(stations)
      .force('center', d3.forceCenter())
      .force('charge', d3.forceManyBody().strength(-10))
      .force('collision', d3.forceCollide().radius(4.5))
      .force('radial', d3.forceRadial(1250))
      .on('tick', () => {
        // @ts-ignore
        nodes_svg_stuff.attr('cx', (d) => d.x).attr('cy', (d) => d.y)
        links_svg_stuff.attr('d', link_path)

        texts_svg
          // @ts-ignore
          .attr('text-anchor', (d) => (d.x < 0 ? 'end' : 'start'))
          // @ts-ignore
          .attr('transform', rotate_text)
      })

    //   })

    // ========================================
    // Functions
    // ========================================
    function link_path(d_link: any) {
      var source = nodesById[d_link.station1],
        target = nodesById[d_link.station2],
        p = d3.path()
      p.moveTo(source.x, source.y)
      p.quadraticCurveTo(0, 0, target.x, target.y)
      return p.toString()
    }

    function rotate_text(d_node: any) {
      var alpha = Math.atan2(-d_node.y, d_node.x),
        distance = 10,
        x = d_node.x + Math.cos(alpha) * distance,
        y = d_node.y - Math.sin(alpha) * distance,
        to_degrees = (angle: any) => (angle * 180) / Math.PI,
        rotation =
          d_node.x < 0 ? 180 - to_degrees(alpha) : 360 - to_degrees(alpha)
      return `translate(${d_node.x < 0 ? x - 15 : x + 15}, ${
        d_node.y < 0 ? y - 15 : y + 15
      })rotate(${rotation})`
      //   return `translate(${x}, ${y})rotate(${rotation})`
    }
  }, [data, dimensions, svgRef, wrapperRef])

  return (
    <div className="relative h-full" ref={wrapperRef}>
      <svg
        style={{
          //   overflow: 'visible',
          //   display: 'block',
          width: '100%',
          height: '100%',
          //   padding: '50px',
        }}
        ref={svgRef}
      ></svg>
    </div>
  )
}

export default LondonForce
