// TODO: add stricter type annotations to each of these functions
/**
 *
 * @param connections
 */
function detectClusters(connections: any) {
  var clusters: any = new Set()
  connections.forEach((link: any) => {
    var added_to: any = []
    clusters.forEach((cluster: any) => {
      if (cluster.has(link.station1) || cluster.has(link.station2)) {
        cluster.add(link.station1)
        cluster.add(link.station2)
        added_to.push(cluster)
      }
    })
    if (added_to.length == 0) {
      clusters.add(new Set([link.station1, link.station2]))
    } else if (added_to.length > 1) {
      var merged = new Set()
      added_to.forEach((cluster: any) => {
        cluster.forEach((node_id: any) => merged.add(node_id))
        clusters.delete(cluster)
      })
      clusters.add(merged)
    }
  })
  clusters = Array.from(clusters)

  // Index clusters by node id
  var clusters_by_id: any = {}
  clusters.forEach((cluster: any) =>
    cluster.forEach((node_id: any) => (clusters_by_id[node_id] = cluster)),
  )
  return clusters_by_id
}

export default detectClusters
