// TODO: add stricter type annotations to each of these functions
/**
 *
 * @param connections
 */
function constructNodesWithLinks(connections: any) {
  var nodesWithLinks = new Set()

  connections.forEach((connection: any) => {
    nodesWithLinks.add(connection.station1)
    nodesWithLinks.add(connection.station2)
  })
  return nodesWithLinks
}

export default constructNodesWithLinks
