// TODO: add stricter type annotations to each of these functions
/**
 *
 * @param connections
 */
function constructStationNodes(stations: any, nodesWithLinks: any) {
  var stationNodes = stations.filter((station: any) =>
    nodesWithLinks.has(station.id),
  )
  return stationNodes
}

export default constructStationNodes
